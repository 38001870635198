@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&display=swap');

.app-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
}

.app-container h1 {
    margin: 30px 0;
}

.spinner-container {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin: 20px 0;
}

.button-container button {
    border: none;
    outline: none !important;
    font-size: 40px;
    background-color: transparent;
}

.navBarContainer {
    width: 100%;
    background-color: #A71930;
    display: flex;
    max-height: 120px;

}

.navBarContainer img {
    object-fit: contain;
    height: 120px;
    padding-right: 30px;
}


.navBarContainer h1 {
    color: white;
}

.buttonImage {
    border: none;
    outline: none !important;
    background-color: transparent;
    max-width: 500px;
    border-radius: 10px;
}

.buttonImage:hover {
    background-color: #000;
}

.buttonImage img {
    cursor: pointer;
    border-radius: 10px;
}


.img__wrap {
    position: relative;
    max-width: 450px;
    cursor: pointer;
}

.img__wrap img {

    border-radius: 10px;

}

.img__description {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(167, 25, 48, 0.82);
    color: #fff;
    visibility: visible;
    opacity: 1;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 30px;
    margin: 0;
    border-radius: 10px;
    font-weight: bold;
    /* transition effect. not necessary */
    transition: opacity .2s, visibility .2s;
    padding: 10px 0;
}

.img__wrap:hover .img__description {
    visibility: visible;
    opacity: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}


.headingContainer {
    text-align: center;
    padding: 20px;
    font-size: 30px;
    font-weight: 500;
}

.descriptionContainer {
    padding:  0 35px;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    text-align: justify;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bodyContainer {
    display: flex;
    justify-content: center;
    margin: 50px 0;
}
